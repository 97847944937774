import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Box, Heading, Text, DataTable, Accordion, AccordionPanel, Card, CardHeader, CardBody, Grid, Image, CardFooter, Anchor } from 'grommet';
import _ from 'lodash';
import { ApplicationState } from '../store';
import { siteSlice } from '../store/Site';

const mapDispatchToProps = {
    ...siteSlice.actions,
}

interface ISportProgramsProps {
}

interface SportProgramsState {
    activeIndex: number[];
}

type SportProgramsProps = ISportProgramsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters


class SportPrograms extends React.PureComponent<SportProgramsProps, SportProgramsState> {
    constructor(props: SportProgramsProps) {
        super(props);

        this.state = {
            activeIndex: [0]
        };
        this.props.setLoading(true);
        this.props.setActiveMenu("programs-menu")
    }

    componentDidMount() {
        this.props.setLoading(false);
    }

    showProgram = (programUrl: string) => {
        window.open(programUrl, "_blank")
    }

    onActivatePanel = (index: number[]) => {
        this.setState({ activeIndex: index });
    }

    public render() {
        return <Box align='center' fill overflow="auto" gap="small" pad={{ left: "small", right: 'small' }}>
            <Accordion fill onActive={this.onActivatePanel} activeIndex={this.state.activeIndex}>
            <AccordionPanel key='2024'
                    label={<Box fill justify='center'><Heading level='3' fill margin={{ vertical: "small" }} textAlign="center" color="brand">2024</Heading></Box>} >
                    <Grid rows="auto" columns="medium" fill gap='small' pad='xsmall'>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='magnolia-west-2024'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Magnolia West vs Morton Ranch</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2024/MagnoliaWest/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2024/MagnoliaWest.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20240907.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                    </Grid>
                </AccordionPanel>
                <AccordionPanel key='2023'
                    label={<Box fill justify='center'><Heading level='3' fill margin={{ vertical: "small" }} textAlign="center" color="brand">2023</Heading></Box>} >
                    <Grid rows="auto" columns="medium" fill gap='small' pad='xsmall'>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='thompkins-2023'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Thompkins</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2023/thompkins/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2023/Morton_Ranch_vs_Thompkins.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20231013.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='taylor-2023'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Katy Taylor</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2023/taylor/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2023/Morton_Ranch_vs_Taylor.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20231005.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='seven-lakes-2023'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Seven Lakes</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2023/sevenlakes/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2023/Morton_Ranch_vs_SevenLakes.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20230929.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='cinco-ranch-2023'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Cinco Ranch</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2023/cincoranch/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2023/Morton_Ranch_vs_CincoRanch.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20230922.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='katy-2023'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Katy</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2023/katy/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2023/Morton_Ranch_vs_Katy.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20230916.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='eisenhower-2023'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Eisenhower</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2023/eisenhower/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2023/Morton_Ranch_vs_Eisenhower.jpg" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 20230824.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                    </Grid>
                </AccordionPanel>
                <AccordionPanel key='2022'
                    label={<Box fill justify='center'><Heading level='3' fill margin={{ vertical: "small" }} textAlign="center" color="brand">2022</Heading></Box>} >
                    <Grid rows="auto" columns="medium" fill gap='small' pad='xsmall'>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='eisenhower-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Eisenhower</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/eisenhower/index.htm") }} align='center' fill>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Eisenhower.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 08272022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='kingwood-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Kingwood Park</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/kingwood/index.htm") }} align='center' >
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_KingwoodPark.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 09032022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='paetow-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Paetow</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/paetow/index.htm") }} align='center' >
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Paetow.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 09092022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='katy-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Katy</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/katy/index.htm") }} align='center' >
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Katy.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 09162022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='cinco-ranch-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Cinco Ranch</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/cincoranch/index.htm") }} align='center' >
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_CincoRanch.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 09222022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='seven-lakes-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Seven Lakes</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/sevenlakes/index.htm") }} align='center'>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_SevenLakes.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 09302022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='katy-taylor-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Taylor</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/taylor/index.htm") }} align='center'>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Taylor.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 10072022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='thompkins-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Thompkins</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/thompkins/index.htm") }} align='center'>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Thompkins.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 10132022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='maydecreek-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Mayde Creek</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/maydecreek/index.htm") }} align='center'>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_MaydeCreek.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 10282022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='hightower-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Hightower</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/hightower/index.htm") }} align='center'>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Hightower.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 11032022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                        <Card width={{ min: 'small' }} height={{ min: 'small' }} key='hightower-2022'>
                            <CardHeader background="brand" pad="small" justify="center">
                                <Text weight="bold">Morton Ranch vs Hightower</Text>
                            </CardHeader>
                            <CardBody pad="small" onClick={() => { this.showProgram("/content/flipbook/2022/hightower/index.htm") }} align='center'>
                                <Image fit="contain" src="images/programs/football/2022/MortonRanch_vs_Hightower.png" />
                            </CardBody>
                            <CardFooter justify='center' pad='xsmall'>
                                <Anchor href='/content/programs/football/Football Program 11112022.pdf' label='Download PDF' target='_blank' />
                            </CardFooter>
                        </Card>
                    </Grid>
                </AccordionPanel>
            </Accordion>
        </Box >;
    }
}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(SportPrograms as any);
