import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import MembershipJoin from './pages/Membership/MembershipJoin';
import MembershipHistory from './pages/Membership/MembershipHistory';
import MembershipManualEntry from './pages/Membership/MembershipManualEntry';
import MembershipList from './pages/Membership/MembershipList';
import WomenInSports from './pages/WomenInSports';
import EventCheckin from './pages/EventCheckin';
import Attendees from './pages/Attendees';
import EventManagement from './pages/EventManagement';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Basketball from './pages/Sports/Basketball';
import Volleyball from './pages/Sports/Volleyball';
import Football from './pages/Sports/Football';
import Promotions from './pages/Promotions';
import SocialMediaApp from './pages/SocialMedia/VolunteerApplication';
import Flyer from './pages/SocialMedia/Flyer';
import SportPrograms from './pages/SportPrograms';
import StampedeTickets from './pages/StampedeTickets';
import RedeemTicket from './pages/RedeemTicket';

import './custom.css'

const initialOptions = {
    "client-id": `${process.env.PAYPAL_CLIENT_ID}`,
    "currency": "USD"
};

export default class App extends React.PureComponent<{}, {}> {

    public render() {
        let currentProgram = '/content/flipbook/2024/MagnoliaWest/index.htm';

        return <PayPalScriptProvider options={initialOptions}>
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/membership/join' component={MembershipJoin} />
                <Route path='/membership/history' component={MembershipHistory} />
                <Route path='/membership/manualEntry' component={MembershipManualEntry} />
                <Route path='/membership/list' component={MembershipList} />
                <Route path='/womenInSports' component={WomenInSports} />
                <Route path='/eventCheckin' component={EventCheckin} />
                <Route path='/events' component={EventManagement} />
                <Route path='/attendees/:eventId' component={Attendees} />
                <Route path='/sports/volleyball' component={Volleyball} />
                <Route path='/sports/basketball' component={Basketball} />
                <Route path='/sports/football' component={Football} />
                <Route path='/promotions/:activeTab?' component={Promotions} />
                <Route path='/socialMedia/application' component={SocialMediaApp} />
                <Route path='/socialMedia/flyer' component={Flyer} />
                <Route path='/programs/football/current' render={() => { window.location.href = currentProgram }} />
                <Route path='/programs' component={SportPrograms} />
                <Route path='/tickets/stampede' component={StampedeTickets} />
                <Route path='/tickets/redeem' component={RedeemTicket} />
            </Layout>
        </PayPalScriptProvider>
    }
}
